const loadYoutubeAPI = (cb, scope = null) => {
  if (typeof window === `undefined`) return;
  const ytIframeTag = document.getElementById('yt-iframe');
  if (ytIframeTag && ytIframeTag !== undefined) {
    cb.call(scope);
    return;
  }

  let tag = document.createElement('script');
  tag.id = 'yt-iframe';
  tag.src = 'https://www.youtube.com/iframe_api';

  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  // init videos on youtube api ready event
  window.onYouTubeIframeAPIReady = () => {
    cb.call(scope);
  };
};

export default loadYoutubeAPI;
