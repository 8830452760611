import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import classNames from 'classnames/bind';
import uid from 'utils/uid';
import styles from './YTPlayer.module.scss';
import loadYoutubeAPI from 'utils/loadYoutubeAPI';
import constants from 'utils/constants';
import getYoutubeVideoIdFromEmbedUrl from 'utils/getYoutubeVideoIdFromEmbedUrl';
import { renderFluidImage } from 'utils/imageHelpers';
import posed from 'react-pose';

const { youtubeApiKey } = constants;
const cx = classNames.bind(styles);

const getTime = timeStr => {
  if (!timeStr) {
    return -1;
  }
  var p = timeStr.split(':'),
    s = 0,
    m = 1;

  while (p.length > 0) {
    s += m * parseInt(p.pop(), 10);
    m *= 60;
  }

  return s;
};

const defaultProps = {
  hidden: {
    opacity: 0,
    applyAtEnd: { display: 'none' }
  },
  visible: {
    applyAtStart: { display: 'block' },
    opacity: 1
  }
};

const Overlay = posed.figure(defaultProps);
const PlayButton = posed.button(
  Object.assign({ disabled: { opacity: 0.5 } }, defaultProps)
);
const CoverImage = posed.figure(defaultProps);

export default class YTPlayer extends Component {
  static propTypes = {
    embed: PropTypes.shape({
      embed_url: PropTypes.string.isRequired,
      width: PropTypes.number,
      height: PropTypes.number
    }),
    onPlayVideo: PropTypes.func,
    delayLoad: PropTypes.number
  };

  static defaultProps = {
    onPlayVideo: null,
    delayLoad: 0
  };

  get playerId() {
    return `ytplayer-${this.uid}`;
  }

  constructor(props) {
    super(props);
    this.uid = uid();

    this.section = {
      start: getTime(props.loopStartTime),
      end: getTime(props.loopEndTime)
    };

    this.hasLoop =
      props.loop &&
      this.section.start >= 0 &&
      this.section.end > this.section.start;

    this.state = {
      currentVideo: null,
      playerId: null,
      videoId: getYoutubeVideoIdFromEmbedUrl(props.embed.embed_url),
      ready: false,
      playing: false,
      isLooping: this.hasLoop,
      fullBleed: this.hasLoop,
      coverImageVisible: true,
      overlayVisible: true,
      playButtonVisible: this.hasLoop ? false : true
    };
  }

  componentWillUnmount() {
    this.player = null;
    clearTimeout(this.loopTimeout);
    window.removeEventListener('resize', this.resizeVideo);
  }

  componentDidMount() {
    if (this.props.delayLoad > 0) {
      setTimeout(() => {
        loadYoutubeAPI(this.createPlayer, this);
        window.addEventListener('resize', this.resizeVideo);
      }, this.props.delayLoad);
      return;
    }
    loadYoutubeAPI(this.createPlayer, this);
    window.addEventListener('resize', this.resizeVideo);
  }

  createPlayer = () => {
    this.loadVideo();
  };

  loadVideo() {
    const youTubeURL = `https://www.googleapis.com/youtube/v3/videos?id=${
      this.props.videoId
    }&key=${youtubeApiKey}&fields=items(id,snippet(title,description,publishedAt,thumbnails),contentDetails)&part=snippet,contentDetails`;
    const onPlayerReady = this.onPlayerReady;
    const onPlayerStateChange = this.onPlayerStateChange;

    axios.get(youTubeURL).then(response => {
      this.setState(
        {
          currentVideo: response.data.items[0],
          playerId: this.playerId
        },
        () => {
          if (!window.YT.Player) {
            return;
          }
          new window.YT.Player(this.playerId, {
            videoId: this.state.videoId,
            width: `100%`,
            height: `100%`,
            playerVars: {
              autoplay: this.props.loop === true,
              modestbranding: 1,
              showinfo: this.props.loop === true ? 0 : 1,
              rel: 0
            },
            events: {
              onReady: onPlayerReady,
              onStateChange: onPlayerStateChange
            }
          });
        }
      );
    });
  }

  loop() {
    this.restartVideoSection();
    this.playVideo();
  }

  onPlayerReady = e => {
    this.player = e.target;
    this.player.setPlaybackQuality('hd1080');
    this.setState({ ready: true }, () => {
      this.player.setVolume(0);
      if (
        this.props.loop &&
        this.section.start >= 0 &&
        this.section.end > this.section.start
      ) {
        this.loop();
      }
    });
  };

  onPlayerStateChange = e => {
    if (e.data === window.YT.PlayerState.PLAYING) {
      this.setState({ playing: true }, () => {
        this.setState({
          overlayVisible: false,
          coverImageVisible: false
        });
      });
      const duration = this.section.end - this.section.start;
      clearTimeout(this.loopTimeout);

      if (this.state.isLooping) {
        this.loopTimeout = setTimeout(
          this.restartVideoSection,
          duration * 1000
        );
      }
    }
  };

  fadeIn() {
    clearInterval(this.volumeInterval);
    this.volumeInterval = setInterval(() => {
      this.changeVolume(1);
    }, 10);
  }

  fadeOut() {
    clearInterval(this.volumeInterval);
    this.volumeInterval = setInterval(() => {
      this.changeVolume(0);
    }, 2);
  }

  changeVolume = vol => {
    if (!this.player) {
      return;
    }
    if (vol === 1) {
      this.player.setVolume(this.player.getVolume() + 1);
      if (this.player.getVolume() === 100) {
        clearInterval(this.volumeInterval);
      }
    } else {
      this.player.setVolume(this.player.getVolume() - 1);
      if (this.player.getVolume() === 0) {
        clearInterval(this.volumeInterval);
      }
    }
  };

  restartVideoSection = () => {
    if (!this.player) {
      return;
    }
    this.player.seekTo(this.section.start);
  };

  playVideo = () => {
    if (!this.player) {
      return;
    }
    this.resizeVideo();
    this.player.playVideo();

    if (this.props.onPlayVideo) {
      this.props.onPlayVideo();
    }
  };

  playVideoWithoutLoop = () => {
    if (!this.player) {
      return;
    }
    if (this.hasLoop) {
      clearTimeout(this.loopTimeout);
      this.setState(
        {
          isLooping: false,
          playButtonVisible: false,
          overlayVisible: true,
          forceOverlay: true
        },
        () => {
          setTimeout(() => {
            this.setState(
              {
                fullBleed: false,
                forceOverlay: false
              },
              () => {
                this.resizeVideo();
                this.player.seekTo(0);
                this.player.playVideo();
                if (this.props.onPlayVideo) {
                  this.props.onPlayVideo();
                }
                this.fadeIn();
                setTimeout(() => {
                  this.setState({ overlayVisible: false });
                }, 400);
              }
            );
          }, 200);
        }
      );
    } else {
      this.player.playVideo();
      if (this.props.onPlayVideo) {
        this.props.onPlayVideo();
      }
      this.fadeIn();
      setTimeout(() => {
        this.setState({ overlayVisible: false, playButtonVisible: false });
      }, 400);
    }
  };

  resizeVideo = () => {
    // find player iframe
    let container = document.getElementById(`${this.playerId}-container`);
    let frame = document.getElementById(`${this.playerId}`);
    if (!frame) {
      return;
    }
    let aspect =
      this.props.embed.width && this.props.embed.height
        ? this.props.embed.height / this.props.embed.width
        : null;

    if (this.state.fullBleed && this.state.isLooping && frame && aspect) {
      const bounds = container.getBoundingClientRect();
      const bw = bounds.width;
      const bh = bounds.height;
      const w = Math.round(bw / aspect);
      const h = Math.round(bh / aspect);
      frame.style.width = `${w}px`;
      frame.style.height = `${h}px`;
    } else {
      frame.style.width = null;
      frame.style.height = null;
    }
  };

  showPlayButton() {
    this.setState({
      playButtonVisible: true,
      overlayVisible: true,
      coverImageVisible: false
    });
  }

  hidePlayButton() {
    this.setState({ playButtonVisible: false });
  }

  reset = () => {
    if (!this.player) {
      return;
    }
    if (this.hasLoop) {
      this.setState(
        {
          fullBleed: true,
          isLooping: true,
          playButtonVisible: true,
          overlayVisible: false
        },
        () => {
          clearInterval(this.volumeInterval);
          this.player.setVolume(0);
          this.resizeVideo();
          this.restartVideoSection();
          this.playVideo();
        }
      );
    } else {
      this.player.stopVideo();
      this.setState({
        playButtonVisible: false,
        playing: false,
        overlayVisible: true
      });
    }
  };

  render() {
    const { coverImage } = this.props;
    return (
      <figure
        id={`${this.playerId}-container`}
        style={this.props.style || null}
        className={`${cx({
          player: true,
          looping: this.state.isLooping,
          fullBleed: this.state.fullBleed
        })}${this.props.className ? ` ${this.props.className}` : ``}`}
      >
        <div id={this.playerId} />
        <Overlay
          className={cx({
            overlay: true
          })}
          pose={
            this.state.overlayVisible
              ? this.state.playing
                ? 'disabled'
                : 'visible'
              : 'hidden'
          }
        />
        {coverImage && (
          <CoverImage
            className={styles.coverImage}
            pose={this.state.coverImageVisible ? 'visible' : 'hidden'}
          >
            {renderFluidImage(coverImage, styles.coverImage)}
          </CoverImage>
        )}
        <PlayButton
          className={styles.playButton}
          pose={
            this.state.playButtonVisible
              ? !this.state.ready
                ? 'disabled'
                : 'visible'
              : 'hidden'
          }
          onClick={this.playVideoWithoutLoop}
        >
          <span className="is-sr-only">Play</span>
          <i>
            <span className={styles.triangle} />
          </i>
        </PlayButton>
      </figure>
    );
  }
}
