import React, { PureComponent } from "react";
import GatsbyImg from "./GatsbyImage";

function getSrcSets(url, dimensions, maxWidth) {
  if (!dimensions || !dimensions.width) {
    return ``;
  }
  return `${url}&w=${(Math.min(
    Math.floor(dimensions.width * 0.33),
    maxWidth[0]
  ),
  360)} 500w, ${url}&w=${Math.max(
    Math.floor(dimensions.width * 0.5),
    maxWidth[1]
  )} 768w, ${url}&w=${Math.min(
    768,
    dimensions.width,
    maxWidth[2]
  )} 1024w, ${url}&w=${Math.min(
    1024,
    dimensions.width,
    maxWidth[3]
  )} 1368w, ${url}&w=${Math.min(1368, dimensions.width, maxWidth[4])} 1920w`;
}

class Img extends PureComponent {
  state = { canRender: false };
  componentDidMount() {
    this.setState({ canRender: true });
  }
  render() {
    const { image, imgProps, className, onLoad } = this.props;
    return (
      <div>
        <div className={className}>
          <img
            alt={image.alt || ""}
            src={image.url}
            width={imgProps.width || null}
            height={imgProps.height || null}
            style={imgProps.style || null}
            onLoad={onLoad || null}
          />
        </div>
      </div>
    );
  }
}

const renderImage = (
  image,
  classes = null,
  type = "fluid",
  props = { imgProps: {} }
) => {
  if (!image) {
    return null;
  }
  let maxWidth = [360, 600, 1024, 1368, 1368];
  if (props.maxWidth) {
    if (Array.isArray(props.maxWidth)) {
      maxWidth = props.maxWidth;
      if (maxWidth.length < 5) {
        while (maxWidth.length < 5) {
          maxWidth.push(props.maxWidth[props.maxWidth.length - 1]);
        }
      }
    } else {
      maxWidth = [
        props.maxWidth,
        props.maxWidth,
        props.maxWidth,
        props.maxWidth,
        props.maxWidth
      ];
    }
    delete props.maxWidth;
  }
  if (image.localFile && image.localFile.childImageSharp) {
    let imgProps = {
      classes,
      [type]: image.localFile.childImageSharp[type],
      ...props.imgProps
    };
    return (
      <GatsbyImg
        className={classes}
        alt={image.alt || ""}
        fadeIn={false}
        loading={`lazy`}
        {...imgProps}
        {...props}
      />
    );
  } else if (
    image.dimensions &&
    image.dimensions.width &&
    image.dimensions.height
  ) {
    const regImgProps = props ? { ...props } : {};

    regImgProps.fluid = {
      src:
        image.dimensions && image.dimensions.width
          ? `${image.url}&w=${Math.min(
              Math.min(Math.floor(image.dimensions.width * 0.33), maxWidth[0]),
              360
            )}`
          : image.url,
      aspectRatio: image.dimensions.width / image.dimensions.height,
      srcSet: getSrcSets(image.url, image.dimensions, maxWidth),
      sizes: ``
    };
    return (
      <GatsbyImg
        className={classes}
        alt={image.alt || ""}
        fadeIn={true}
        loading={`lazy`}
        {...regImgProps}
        {...props}
      />
    );
  } else {
    const regImgProps = props ? { ...props } : {};
    return (
      <Img
        image={image}
        imgProps={regImgProps}
        onLoad={props.onLoad}
        className={classes}
      />
    );
  }
};

export const renderFluidImage = (image, classes, props = {}) => {
  if (!props) {
    props = {};
  }
  return renderImage(image, classes, "fluid", props);
};
