import getZeroedNumber from 'utils/getZeroedNumber';

const uid = (levels = 4) => {
  let n = 0;
  let str = `${getZeroedNumber(Math.floor(Math.random() * 10000), 4)}`;
  while (n < levels - 1) {
    str += `-${getZeroedNumber(Math.floor(Math.random() * 10000), 4)}`;
    n++;
  }
  return str;
};

export default uid;
